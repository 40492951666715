import styled from "styled-components";

export const Card = styled.div`
  background: #003049;
  border: 1px solid #000000;
  box-sizing: border-box;
  /* Shadow - Card */

  backdrop-filter: blur(42px);
  border-radius: 10px;
`;

export const CardContainer = styled.div`
  position: relative;
  height: 200px !important;
`;

export const ImageOfLogos = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;
