import styled from "styled-components";

export const Title = styled.div`
  margin: 30px auto;
  font-family: Neutral Face;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 42px;
`;
