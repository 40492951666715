import React from 'react'
import { Title } from './ConsultingElements'
import schema from '../../../../images/servicesPage/Consulting/consultingSchema.svg'
import schemaMobile from '../../../../images/servicesPage/Consulting/consultingSchemaMobile.svg'
import useWindowSize from "@rooks/use-window-size";



const Consulting = () => {
    const { innerWidth } = useWindowSize();
    return (
        <div style={{ margin: '50px auto' }}>
            <Title>
                expertise in mapping real-life business processes into software applications. Together we can build valuable solutions to your problems at the lowest complexity budget.
            </Title>
            {innerWidth < 768 ? (<img style={{ width: '100%' }} src={schemaMobile} alt="" />) : <img style={{ width: '100%' }} src={schema} alt="" />}

        </div>
    )
}

export default Consulting