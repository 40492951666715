import React from "react";
import {
  Container,
  Title,
  GridContainer,
  CardContainer,
  Image,
  TextContainer,
  CardTitle,
  CardDescription,
  Button,
} from "./ServicesElements";
import data from "./data";

const Services = () => {
  return (
    <Container className="container">
      <Title>OUR SERVICES</Title>
      <GridContainer>
        {data.map((card, index) => {
          return (
            <CardContainer key={index}>
              <div style={{ width: 'auto', display: 'flex', justifyContent: 'center' }}>
                <Image src={card.image} alt="" />
              </div>
              <TextContainer>
                <CardTitle>{card.title}</CardTitle>
                <CardDescription>{card.description}</CardDescription>
              </TextContainer>
            </CardContainer>
          );
        })}
      </GridContainer>
      <Button to={{ pathname: "/services", state: 'CONSULTING' }}>Learn More</Button>
    </Container>
  );
};

export default Services;
