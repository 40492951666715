import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import contactUsPage from "./pages/ContactUs";
import AboutUspage from "./pages/AboutUs"
// import Navbar from "./components/General/Navbar";
// import { Nav } from "./components/General/Navbar/NavbarElements";

// import PrivacyPolicyPage from "./pages/PrivacyPolicy"; 
import Services from "./components/ServicesPage/Services.jsx";

// import Navbar from "./components/General/Navbar";

require('dotenv').config()


function App() {
  return (
    <Router>
      <Switch>
        {/* <Navbar transparent={false} /> */}
        <Route path="/" component={Home} exact />
        <Route path="/contact" component={contactUsPage} exact />
        <Route path="/about" component={AboutUspage} exact />
        <Route path="/services" component={Services} exact />
        {/* <Route path="/PrivacyPolicy" component={PrivacyPolicyPage} exact /> */}
      </Switch>
    </Router>
  );
}

export default App;
