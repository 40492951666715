import React from 'react'
import {
  SidebarContainer,
  SidebarLogo,
  IconLeft,
  IconRight,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarLInktoContacts,
  // SidebarRoute,
} from './SidebarElements'
import logo from '../../../images/logo/LOGO.svg'


const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <IconLeft>
        <SidebarLogo>
          <img src={logo} style={{ width: '150px' }} alt='Betech Logo' />
        </SidebarLogo>
        {/* <div style={{ color: 'whitesmoke' }}>betech</div> */}
      </IconLeft>
      <IconRight onClick={() => toggle()}>
        <CloseIcon />
      </IconRight>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='#' onClick={() => toggle()}>
            <img src={require('../../../images/Sidebar/home.png')} style={{ height: '25px', paddingRight: '20px' }} alt="home logo" />
            Home
          </SidebarLink>
          <SidebarLink to='about' onClick={() => toggle()}>
            <img src={require('../../../images/Sidebar/aboutUs.png')} style={{ height: '25px', paddingRight: '20px' }} alt="about logo" />
            About
          </SidebarLink>
          <SidebarLink to='services' onClick={() => toggle()}>
            <img src={require('../../../images/Sidebar/settings.png')} style={{ height: '25px', paddingRight: '20px' }} alt="services logo" />
            Services
          </SidebarLink>
          <SidebarLInktoContacts to='contactUs' onClick={() => toggle()}>
            <img src={require('../../../images/Sidebar/contactUs.png')} style={{ height: '25px', paddingRight: '20px' }} alt="contact us logo" />
            Contact Us
          </SidebarLInktoContacts>
        </SidebarMenu>
        <SideBtnWrap>
          {/* <SidebarRoute to="/src/pages/ContactUs.js">Contact Us</SidebarRoute> */}
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
