import React from 'react'
import useWindowSize from '@rooks/use-window-size'
import ParticleImage, { Vector, forces } from 'react-particle-image'
// import Logo from '../../../images/logo/LOGO.png'
import Logo2 from '../../../images/betech.png'
import { HeroContainer, HeroBg, VideoBg } from '../../HeroSection/HeroElements'
import Video from '../../../videos/video.mp4'

// This could be any component that generates HTML.
// const SuperStrong = ({ children }) => {
//     return <strong style={{ fontSize: '80px' }}>{children}</strong>
// }

const particleOptions = {
    filter: ({ x, y, image }) => {
        // Get pixel
        const pixel = image.get(x, y)
        // Make a particle for this pixel if blue > 50 (range 0-255)
        return pixel.b > 50
    },
    color: ({ x, y, image }) => '#61dafb',
    radius: () => Math.random() * 1.5 + 0.5,
    mass: () => 40,
    friction: () => 0.1,
    initialPosition: ({ canvasDimensions }) => {
        return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2)
    },
}

const motionForce = (x, y) => {
    return forces.disturbance(x, y, 50)
}

const BetechHero = () => {
    const { innerWidth, innerHeight } = useWindowSize()

    return (
        <>
            <HeroContainer style={{ display: 'flex', flexDirection: 'column' }}>
                <HeroBg>
                    <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
                </HeroBg>
                <ParticleImage
                    src={Logo2}
                    width={Number(innerWidth)}
                    height={Number(innerWidth < 768 ? innerHeight / 1.5 : innerHeight)}
                    scale={innerWidth < 768 ? 0.5 : 1}
                    entropy={10}
                    maxParticles={4000}
                    particleOptions={particleOptions}
                    mouseMoveForce={motionForce}
                    touchMoveForce={motionForce}
                    backgroundColor='#00000000'
                    style={{ zIndex: 10 }}
                />
                {/* <TypeIt>
                Your go to <SuperStrong>Outsourcing Company.</SuperStrong>
            </TypeIt> */}
                {/* <div style={{ color: '#7f83c7', margin: '-300px 0  200px', fontSize: '40px', zIndex: 20 }}>Your go to <SuperStrong>Outsourcing Company.</SuperStrong></div>S */}
            </HeroContainer>
        </>
    )
}

export default BetechHero
