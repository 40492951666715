import React from "react";
import {
  Container,
  Title,
  Description,
  UnorderedList,
  ListItem,
  Subtitle,
  Technologies,
} from "./DesignAndDevelopmentElements";
import { cloudEngineeringData, UIandUXData, ArtificialIntelligence } from "./data";
import useWindowSize from "@rooks/use-window-size";

const Card1 = () => {
  const { innerWidth } = useWindowSize();
  return (
    <Container dark={false}>
      <div style={innerWidth < 768 ? { padding: "20px 28px" } : { padding: "20px 45px" }}>
        <Title size={28}>Web Development</Title>
        <Description>
          We offer Web app development using the latest technology according to
          your needs. We offer front-end development as well as backed
          development. We make sure to test the applications we create so that
          they are supported on all possible browsers.
        </Description>
        <UnorderedList>
          <ListItem>Frontend Development</ListItem>
          <ListItem>Backend development</ListItem>
          <ListItem>CMS</ListItem>
          <ListItem>Testing</ListItem>
        </UnorderedList>
      </div>
      <div style={innerWidth < 768 ? { padding: "20px 28px", marginTop: '-70px' } : { padding: "20px 45px" }}>
        <Title size={22}>Technology Stack</Title>
        <Subtitle>Front-End</Subtitle>
        <Technologies>
          HTML5 | CSS3 | TypeScript | JavaScript | Angular | React | Bootstrap |
          Vue.js | Asp.net | Python Web Development | Ruby on Rails
        </Technologies>
        <Subtitle>Back-End</Subtitle>
        <Technologies>Java | .NET | Python | Go | Node.js</Technologies>
        <Subtitle>Databases</Subtitle>
        <Technologies>
          Microsoft SQL Server | MySQL | Oracle | SQL Azure PostgreSQL | MongoDB
          | Cassandra | Couchbase
        </Technologies>
      </div>
    </Container>
  );
};

const Card2 = () => {
  const { innerWidth } = useWindowSize();
  return (
    <Container dark={true}>
      <div style={innerWidth < 768 ? { padding: "20px 28px" } : { padding: "20px 45px" }}>
        <Title size={28}>Mobile Development</Title>
        <Description>
          The process of mobile development is tied strongly to your business
          needs. Here at betech we evaluate your needs and try to deliver native
          or cross-platform applications to fulfill your objectives and the
          reach of possible customers.
        </Description>
      </div>
      <div style={innerWidth < 768 ? { padding: "20px 28px", marginTop: '-70px' } : { padding: "20px 45px" }}>
        <Title size={22}>Technology Stack</Title>
        <Subtitle>Front-End</Subtitle>
        <Technologies>
          Kotlin (Android) | Swift, Objective C (IOS) | React Native (Cross Platform) | Flutter(Cross Platform) | Ionic (Cross Platform)
        </Technologies>
        <Subtitle>Back-End</Subtitle>
        <Technologies>Java | .NET | Python | Go | Node.js</Technologies>
        {/* <Subtitle>Databases</Subtitle>
        <Technologies>
          Microsoft SQL Server | MySQL | Oracle | SQL Azure PostgreSQL | MongoDB
          | Cassandra | Couchbase
        </Technologies> */}
      </div>
    </Container>
  );
};

const Card3 = () => {
  const { innerWidth } = useWindowSize();
  return (
    <Container dark={false}>
      <div style={innerWidth < 768 ? { padding: "20px 28px" } : { padding: "20px 45px" }}>
        <Title size={28}>Desktop Apps development</Title>
        <Description>
          Being able to deliver any type of request in terms of apps, be it web, mobile or desktop is what distinguishes us.
        </Description>
      </div>
      <div style={innerWidth < 768 ? { padding: "20px 28px", marginTop: '-70px' } : { padding: "20px 45px" }}>
        <Title size={22}>Technology Stack</Title>
        <Subtitle>User Interface</Subtitle>
        <Technologies>
          Electron | Java | Python | Ruby on Rails
        </Technologies>
        {/* <Subtitle>Back-End</Subtitle>
        <Technologies>Java | .NET | Python | Go | Node.js</Technologies> */}
        {/* <Subtitle>Databases</Subtitle>
        <Technologies>
          Microsoft SQL Server | MySQL | Oracle | SQL Azure PostgreSQL | MongoDB
          | Cassandra | Couchbase
        </Technologies> */}
      </div>
    </Container>
  );
};

const Card4 = () => {
  const { innerWidth } = useWindowSize();
  return (
    <Container dark={true}>
      <div style={innerWidth < 768 ? { padding: "20px 28px", alignItems: "center" } : { padding: "20px 45px", alignItems: "center" }}>
        <Title size={28}>Cloud Engineering</Title>
        <Description>
          1. Development & Integration of cloud services when developing an
          application for the first time.
          <br />
          <br />
          2. Migration from traditional ways of developing and serving an
          application to cloud solutions.
          <br />
          <br />
          3. Manage cloud services in any of the major cloud providers.
          <br />
          <br />
          4. Engineering of private clouds and/or hybrid clouds with major cloud
          providers.
        </Description>
      </div>
      <div style={innerWidth < 768 ? { padding: "20px 28px", marginTop: '-70px' } : { padding: "20px 45px" }}>
        <Title size={22}>Technology Stack</Title>
        {cloudEngineeringData.map((item) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
                margin: "15px auto",
              }}
            >
              <img src={item.logo} alt="" />
              <span>{item.title}</span>
              <br />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

const Card5 = () => {
  const { innerWidth } = useWindowSize();
  return (
    <Container dark={false}>
      <div style={innerWidth < 768 ? { padding: "20px 28px", alignItems: "center" } : { padding: "20px 45px", alignItems: "center" }}>
        <Title size={28}>UI & UX Design</Title>
        <Description>
          We combine impeccable web design, centered on your brand identity,
          with services tailored to your objectives. Our design team works
          closely with you to craft responsive websites that bring your ideas to
          life on any screen.
        </Description>
      </div>
      <div style={innerWidth < 768 ? { padding: "20px 28px", marginTop: '-70px' } : { padding: "20px 45px" }}>
        <Title size={22}>Technology Stack</Title>
        {UIandUXData.map((item) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
                margin: "15px auto",
              }}
            >
              <img src={item.logo} alt="" />
              <span>{item.title}</span>
              <br />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

const Card6 = () => {
  const { innerWidth } = useWindowSize();
  return (
    <Container dark={true}>
      <div style={innerWidth < 768 ? { padding: "20px 28px", alignItems: "center" } : { padding: "20px 45px", alignItems: "center" }}>
        <Title size={28}>Artificial Intelligence</Title>
        <Description>
          We work hard to be present in every aspect of the software development industry. As such we are able to deliver projects that require use of AI to achieve client's
        </Description>
      </div>
      <div style={innerWidth < 768 ? { padding: "20px 28px", marginTop: '-70px' } : { padding: "20px 45px" }}>
        <Title size={22}>Technology Stack</Title>
        {ArtificialIntelligence.map((item) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
                margin: "15px auto",
              }}
            >
              <img src={item.logo} alt="" />
              <span>{item.title}</span>
              <br />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

const DesignAndDevelopment = () => {
  return (
    <>
      <Card1 />
      <Card2 />
      <Card3 />
      <Card4 />
      <Card5 />
      <Card6 />
    </>
  );
};

export default DesignAndDevelopment;
