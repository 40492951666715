import webDevelopment from '../../../images/itSolutions_logo/WebDevelopmentLogo.svg'
import mobileDevelopment from '../../../images/itSolutions_logo/mobileDevelopment.svg'
import desktopApps from '../../../images/itSolutions_logo/deskopAppsLogo.svg'
import cloudEngineering from '../../../images/itSolutions_logo/cloudEngineeringLogo.svg'
import UIandUXLogo from '../../../images/itSolutions_logo/UI&UXLogo.svg'
import AIandMachineLearning from '../../../images/itSolutions_logo/ai&machineLearningLogo.svg'




const data = [
  {
    logo: webDevelopment,
    title: "Web Development",
    description:
      "A full-size website for any of your needs. What characterizes us is clean code and delivery on time.",
  },
  {
    logo: mobileDevelopment,
    title: "Mobile Development",
    description:
      "Native and cross-platform apps to cover any of your needs.",
  },
  {
    logo: desktopApps,
    title: "Desktop Applications",
    description:
      "We deliver desktop apps that cover a wide range of applications.",
  },
  {
    logo: cloudEngineering,
    title: "Cloud Engineering",
    description:
      "Assist you with any needs in transforming to the clouds.",
  },
  {
    logo: UIandUXLogo,
    title: "UI & UX Design",
    description:
      "We understand that your customers are a priority. As such we work to deliver the best experience and visual appearance to leave your customers satisfied",
  },
  {
    logo: AIandMachineLearning,
    title: "Artificial Intelligence",
    description:
      "There are many applications that need the assist of artificial intelligence, and we are here to assist you with it.",
  },
];

export default data;
