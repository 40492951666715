import React from 'react'
import { Description, Title, CardsContainer, Card, Icon, CardTitle } from './OutsourcingElements'
import data from './data'

const Outsourcing = () => {
  return (
    <div style={{margin: '50px auto'}}>
      <Description>
        Staff augmentation is the process of requesting and having a set of specialised team members hired from outside the company to meet the needs for the development of a certain project on time, on budget and without errors.

        Here at betech we make sure to find the right candidates that comply with your needs. The process of offshoring is a breeze with betech’s assistance and profesionalism.

        We make sure to screen thoroughly each of the specialists, flexible scale up and down according to the needs of your company, strict legal non-discolsure agreements (NDA) as per requests as well as no contract lock ins.

        increase the size and productivity of your internal development team
        The staff augmentation model of outsourcing helps quickly fill the resource gap of your in-house team with remote team members managed by you directly and fully dedicated to your project for a chosen period.
      </Description>
      <Title>The benefits of Staff Augmentation with betech</Title>

      <CardsContainer>
        {data.map(card => {
          return (
            <Card>
              <Icon src={card.icon} />
              <CardTitle>
                {card.title}
              </CardTitle>
            </Card>
          )
        })}
      </CardsContainer>
    </div>
  )
}

export default Outsourcing