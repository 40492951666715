import styled from "styled-components";
import { Colors } from "../../../constants/colors";
import { Link as LinkR } from 'react-router-dom'

export const ServicesContainer = styled.div`
  max-height: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #010606;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 20px 50px 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 768px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2 ease-in-out;
  height: -webkit-fill-available;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;
  margin-top: 32px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  text-align: justify;
  color: blue;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: justify;
`;

export const Container = styled.div`
  /* background-color: #000000c7; */
  margin: 50px auto 0;
  padding: 50px 10px;
  @media screen and (max-width: 768px) {
    margin: 25px auto;
    padding: 25px 10px;
  }
`;

export const Title = styled.div`
  font-family: Neutral Face;
  font-style: normal;
  /* font-weight: bold; */
  font-weight: normal;
  font-size: 72px;
  line-height: 75px;
  /* color: ${Colors.secondary}; */
  color: #ffffff;
  margin: 50px 0 70px 0;
  @media screen and (max-width: 768px) {
    font-size: 42px;
    line-height: 50px;
    margin: 30px 0;
  }
`;

export const GridContainer = styled.div`
  height: 100%;
  gap: 20px;
  width: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 50px;

  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    overflow: scroll;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    /* padding: 10px 50px; */
    padding-bottom: 20px;
    scrollbar-color: #c3ffff #c3ffff;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* justify-content: space-between; */
  align-items: 'center';
  height: inherit;
  border: 1px solid #ffffff;
  border-radius: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between ;
  padding: 0px 30px 30px;
`;
export const Image = styled.img`
  padding-top: 30px;
  width: auto;
  height: 220px;
  margin-bottom: 10px;
  object-fit: contain;
`;

export const CardTitle = styled.div`
  font-family: Neutral Face;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

export const CardDescription = styled.div`
  /* font-family: Roboto; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

export const Button = styled(LinkR)`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 25px auto;
  width: fit-content;
  padding: 18px 80px;
  color: ${({ styleGray }) => (styleGray ? '#333333' : '#ffffff')};
  background-color: ${({ styleGray }) => (styleGray ? '#777777' : Colors.secondary)};
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  /* background-color: ${Colors.secondary}; */

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    background-color: ${Colors.primary};
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 50px;
  }
`;
