import React from "react";
import { scrollToTop } from "react-scroll/modules/mixins/animate-scroll";
import ContactUs from "../components/ContactUs";
import Navbar from "../components/General/Navbar";

const contactUsPage = () => {
  return (
    <>
      <Navbar />
      <scrollToTop />
      <ContactUs />
    </>
  );
};

export default contactUsPage;
