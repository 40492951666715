import React from 'react'
import {Container, FormWrap, Icon, FormContent, Form, FormH1, FormLabel, FormInput, FormButton, Text} from './ContactUsElements'

const ContactUs = () => {
  return (
    <Container>
      <FormWrap>
        <Icon to='/'>betech</Icon>
        <FormContent>
          <Form action="#">
            <FormH1>Contact Us for more information.</FormH1>
            <FormLabel htmlFor='for'>Name</FormLabel>
            <FormInput type='input' required/>
            <FormLabel htmlFor='for'>Surname</FormLabel>
            <FormInput type='input' required/>
            <FormLabel htmlFor='for'>Email</FormLabel>
            <FormInput type='email' required/>
            <FormLabel htmlFor='for'>Description</FormLabel>
            <FormInput type='textarea' required/>
            <FormButton htmlFor='submit'>Send</FormButton>
            <Text>Forgot Password</Text>
          </Form>
        </FormContent>
      </FormWrap>
    </Container>
  )
};

export default ContactUs;
