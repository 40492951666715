import styled from "styled-components";

export const Container = styled.div`
  margin: 50px auto;
  background-color: ${({ dark }) => (dark ? "#062F38" : "rgba(242, 242, 242, 0.85);")};
  border-radius: 33px;
  color: ${({ dark }) => (dark ? "#ffffff" : "#000000")};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h2`
  margin: 30px auto;
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  font-size: ${({ size }) => size}px;
  line-height: 36px;
`;

export const Description = styled.p`
  margin: 20px auto;
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
`;

export const UnorderedList = styled.ul`
  margin-bottom: 25px;
  list-style: none;
`;

export const ListItem = styled.li`
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;

  &::before {
    font-weight: normal;
    content: "✓";
    padding-right: 8px;
  }
`;

export const Subtitle = styled.div`
  margin-top: 10px;
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;
`;

export const Technologies = styled.div`
  margin: 10px auto 15px auto;
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
`;
