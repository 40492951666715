import consulting from '../../../images/services/consulting.svg'
import supportAndMaintenance from '../../../images/services/supportAndMaintenance.svg'
import softwareDevelopment from '../../../images/services/softwareDevelopment.svg'
import outsourcing from '../../../images/services/outsourcing.svg'


const data = [
    { image: consulting, title: 'Consulting', description: 'We help reduce your fees and increase your overall revenue' },
    { image: supportAndMaintenance, title: 'Support & Maintenance', description: 'In addition to delivering project from the start, we offer support in terms of UI&UX design, web, mobile, AI development, as well as cloud support.' },
    { image: softwareDevelopment, title: 'Software Design and Development', description: 'We make sure to deliver an application that can fulfill your needs, on time, with efficiency and within the highest standards' },
    { image: outsourcing, title: 'Sourcing & Recruitment', description: 'Outsource your project with betech to have the best professionals and to increase efficiency with reduced costs.' },
];

export default data;
