import React from "react";
import { Container, Title, Button } from '../../Home/Services/ServicesElements'
import { Separator, Row, Description, Image } from './Elements'
// import image from '../../../images/aboutUsHomepage.jpg'
import image from '../../../images/Group 11.png'
import useWindowSize from '@rooks/use-window-size'

// import Link from 'react-router-dom'

const AboutUs = () => {

  const { innerWidth } = useWindowSize()

  return (
    <>
      <Separator />
      <div style={{}}>
        <Container className="container" style={{ marginTop: innerWidth > 760 ? '-70px' : '-250px' }}>
          <Title>ABOUT US</Title>
          <Row>
            <Description>
              betech is a Software Development, Recruitment & Outsourcing vendor
              enabling clients to navigate their Digital Transformation and assist
              in delivering solutions to organizational bottlenecks as well as
              reduced costs of the project.
            </Description>
            <Image src={image} />
          </Row>
          <Button to='/about'>Learn More</Button>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
