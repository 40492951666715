import React from 'react'
import { Container, Title, Button } from '../../Home/Services/ServicesElements'
import { Row } from './TechnologiesElement'
import data from './data'
import useWindowSize from "@rooks/use-window-size";



const Technologies = () => {
  const { innerWidth } = useWindowSize();
  return (
    <Container className='container'>
      <Title>Technologies</Title>
      <Row>
        {data[0].map((logo, index) => {
          return (
            <div style={{ width: '100%' }} key={index}>
              <img style={(index % 2 === 1 && innerWidth > 768) ? { paddingTop: '70px', width: 'inherit' } : { width: 'inherit' }} src={logo} alt="" />
            </div>
          )
        })}
      </Row>

      <Row style={(innerWidth > 768) ? { width: '100%', marginTop: '-60px', marginBottom: '20px' } : { width: '100%', marginBottom: '20px' }}>
        {data[1].map((logo, index) => {
          return (
            <div style={{ width: '100%' }} key={index}>
              <img style={(index % 2 === 1 && innerWidth > 768) ? { paddingTop: '70px', width: 'inherit' } : { width: 'inherit' }} src={logo} alt="" />
            </div>
          )
        })}
      </Row>

      <Button to={{ pathname: "/services", state: 'DESIGN_AND_DEVELOPMENT' }}>Learn More</Button>

    </Container>
  )
}

export default Technologies