import styled from "styled-components";

export const AllTiles = styled.div`
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 70px;

  @media screen and (max-width: 768px) {
    padding-top: 45px;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
`;

export const TileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  color: #ffffff;
  @media screen and (max-width: 768px) {
    margin-top: 15px;
    gap: 10px;
  }
`;

export const TileLogo = styled.img`
  width: 86px;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 65px;
  }
`;

export const TileTitle = styled.div`
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.17em;
  color: #ffffff;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.12em;
  }
`;
export const TileDescription = styled.div`
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
`;
