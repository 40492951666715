import React from 'react'
import { Container, Title } from '../Services/ServicesElements'
import {
  Grid,
  Choice,
  Row,
  Input,
  Label,
  HrefLabel,
  InfoHref,
  Button,
} from './ContactUsElements'
import emailjs from '@emailjs/browser'
import './ContactUs.css'
import Loading from '../../../images/Loading.svg'

const Modal = ({ showSuccessMessage, validation }) => {
  return (
    <>
      {showSuccessMessage === 'loading' ? (
        <img style={{ height: '50px' }} src={Loading} alt='' />
      ) : showSuccessMessage === 'success' ? (
        <div className='audun_success'>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              gap: '10px',
            }}
          >
            <i className='fa fa-check-circle' aria-hidden='true'></i>
            <div>Form submitted succesfully</div>
          </div>
          <div>Thank you for your interest! We will contact you soon!</div>
        </div>
      ) : showSuccessMessage === 'failed' ? (
        <div className='audun_warn'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>
          Oops! Something went wrong! Try reloading the page!
        </div>
      ) : (
        <div></div>
      )}

      {validation && (
        <div className='audun_info'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>
          {validation}
        </div>
      )}
    </>
  )
}

const BetechContactInfo = () => {
  return (
    <div style={{ height: '100%', alignItems: 'center', width: '200px' }}>
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <HrefLabel>
          <span style={{ width: '20px', height: 'auto' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='22'
              viewBox='0 0 30 22'
              fill='none'
            >
              <path
                d='M27.8571 0.5H2.14286C1.57454 0.5 1.02949 0.725765 0.627628 1.12763C0.225765 1.52949 0 2.07454 0 2.64286V19.7857C0 20.354 0.225765 20.8991 0.627628 21.3009C1.02949 21.7028 1.57454 21.9286 2.14286 21.9286H27.8571C28.4255 21.9286 28.9705 21.7028 29.3724 21.3009C29.7742 20.8991 30 20.354 30 19.7857V2.64286C30 2.07454 29.7742 1.52949 29.3724 1.12763C28.9705 0.725765 28.4255 0.5 27.8571 0.5ZM25.5 2.64286L15 9.90714L4.5 2.64286H25.5ZM2.14286 19.7857V3.61786L14.3893 12.0929C14.5686 12.2173 14.7817 12.284 15 12.284C15.2183 12.284 15.4314 12.2173 15.6107 12.0929L27.8571 3.61786V19.7857H2.14286Z'
                fill='white'
                fillOpacity='0.2'
              />
              <path
                d='M25.7845 3.05404L27.1015 2.14286H25.5H4.5H2.89847L4.21553 3.05404L14.7155 10.3183L15 10.5151L15.2845 10.3183L25.7845 3.05404ZM1.64286 19.7857V20.2857H2.14286H27.8571H28.3571V19.7857V3.61786V2.66378L27.5726 3.20671L15.3262 11.6817L15.3257 11.682C15.2301 11.7484 15.1164 11.784 15 11.784C14.8836 11.784 14.7699 11.7484 14.6743 11.682L14.6738 11.6817L2.42739 3.20671L1.64286 2.66378V3.61786V19.7857ZM2.14286 1H27.8571C28.2929 1 28.7107 1.17309 29.0188 1.48118C29.3269 1.78928 29.5 2.20715 29.5 2.64286V19.7857C29.5 20.2214 29.3269 20.6393 29.0188 20.9474C28.7107 21.2555 28.2929 21.4286 27.8571 21.4286H2.14286C1.70714 21.4286 1.28928 21.2555 0.981182 20.9474C0.673086 20.6393 0.5 20.2214 0.5 19.7857V2.64286C0.5 2.20714 0.673086 1.78928 0.981182 1.48118C1.28928 1.17309 1.70714 1 2.14286 1Z'
                stroke='white'
                strokeOpacity='0.8'
              />
            </svg>
          </span>
        </HrefLabel>
        <InfoHref href='mailto:info@betech.al'>info@betech.al</InfoHref>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <HrefLabel>
          <span style={{ width: '20px', height: 'auto' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              viewBox='0 0 37 36'
              fill='none'
            >
              <path
                d='M16.0901 8.39989L10.4517 1.89155C9.80173 1.14155 8.61006 1.14489 7.85506 1.90155L3.2184 6.54655C1.8384 7.92822 1.44339 9.97989 2.24173 11.6249C7.01109 21.4998 14.9751 29.475 24.8434 34.2582C26.4867 35.0566 28.5367 34.6616 29.9167 33.2799L34.5967 28.5916C35.3551 27.8332 35.3567 26.6349 34.6001 25.9849L28.0667 20.3766C27.3834 19.7899 26.3217 19.8666 25.6367 20.5532L23.3634 22.8299C23.247 22.9519 23.0938 23.0323 22.9273 23.0588C22.7608 23.0853 22.5902 23.0564 22.4417 22.9766C18.7258 20.8367 15.6434 17.7503 13.5084 14.0316C13.4284 13.8828 13.3995 13.7119 13.426 13.5451C13.4525 13.3784 13.5329 13.2249 13.6551 13.1082L15.9217 10.8399C16.6084 10.1499 16.6834 9.08322 16.0901 8.39822V8.39989Z'
                stroke='white'
                strokeOpacity='0.8'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>
        </HrefLabel>
        <InfoHref href='tel:+355692227434'>+355692227434</InfoHref>
      </div>
    </div>
  )
}

// Duhet ta bej qe kur user te plotesoje nje forme dhe ti bej send, duhet ti hiqet forma aktuale dhe ti dali thank u for your interest, we will be back with you asap, dhe te mos kete mundesi te plotesoje me forme tj pervecse kur te beje reload

const ContactUs = () => {
  // State of the form elements
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [subject, setSubject] = React.useState('')

  // State to check if the form is submitted or not
  const [isFormSubmited, setISFormSubmitted] = React.useState(false)

  // Validation and success message
  const [showSuccessMessage, setShowSuccessMessage] = React.useState('')
  const [validation, setValidation] = React.useState(null)

  // Button disabled
  // eslint-disable-next-line
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false)

  const sendEmail = () => {
    const mailDetails = {
      name: name,
      email: email,
      description: description,
      subject: subject,
    }
    setShowSuccessMessage('loading')
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        mailDetails,
        process.env.REACT_APP_EMAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result)
          if (result.status === 200) {
            setValidation('')
            setShowSuccessMessage('success')
            setName('1')
            setEmail('1')
            setDescription('1')
            setSubject('1')
          }
        },
        (error) => {
          setShowSuccessMessage('failed')
          console.log(error.text)
        }
      )
  }

  const consoleForm = (e) => {
    e.preventDefault()

    setName(e.target[0].value)
    setEmail(e.target[1].value)
    setDescription(e.target[2].value)
  }

  React.useEffect(() => {
    if (name || email || description) {
      if (!subject || !name || !email || !description) {
        setValidation('Please select a subject and fill in all the fields!')
      } else if (name === '1') {
        // eslint-disable-next-line
        {
          return
        }
      } else {
        sendEmail()
        setValidation(null)
        setISFormSubmitted(true)
      }
    }
    // eslint-disable-next-line
  }, [name, email, description])

  React.useEffect(() => {
    if (showSuccessMessage === 'failed') {
      const interval = setInterval(() => {
        setShowSuccessMessage('submitted')
      }, 4000)
      return () => clearInterval(interval)
    }
  }, [showSuccessMessage])

  return (
    <div id='contactUs'>
      <Container className='container'>
        <Title>CONTACT US</Title>
        {!isFormSubmited && (
          <div>
            <div
              style={{ color: 'rgba(255, 255, 255, 0.7)', padding: '20px 0px' }}
            >
              I'm interested in...
            </div>
            <Grid>
              <Choice
                style={
                  subject === 'Consulting'
                    ? { backgroundColor: 'white', color: 'black' }
                    : {}
                }
                onClick={() => setSubject('Consulting')}
              >
                Consulting
              </Choice>
              <Choice
                style={
                  subject === 'Application Development'
                    ? { backgroundColor: 'white', color: 'black' }
                    : {}
                }
                onClick={() => setSubject('Application Development')}
              >
                Application Development
              </Choice>
              <Choice
                style={
                  subject === 'Staff Augmentation / Outsourcing'
                    ? { backgroundColor: 'white', color: 'black' }
                    : {}
                }
                onClick={() => setSubject('Staff Augmentation / Outsourcing')}
              >
                Staff Augmentation / Outsourcing
              </Choice>
              <Choice
                style={
                  subject === 'Support and Maintenance'
                    ? { backgroundColor: 'white', color: 'black' }
                    : {}
                }
                onClick={() => setSubject('Support and Maintenance')}
              >
                Support and Maintenance
              </Choice>
            </Grid>
            <Row className='login-box'>
              <form
                style={{ width: '100%' }}
                // ref={formRef}
                spellCheck='true'
                onSubmit={(e) => {
                  consoleForm(e)
                }}
              >
                <div className='user-box' style={{ margin: '20px auto' }}>
                  <Input type='text' name='' />
                  <Label>name</Label>
                </div>
                <div className='user-box' style={{ margin: '20px auto' }}>
                  <Input type='email' name='' />
                  <Label>email</Label>
                </div>
                <div className='user-box' style={{ margin: '20px auto' }}>
                  <Input name='description' />
                  <Label>description</Label>
                </div>
                <Button
                  styleGray={isButtonDisabled}
                  type='submit'
                  value='Send'
                  name='submitButton'
                  disabled={isButtonDisabled}
                  onClick={() => {
                    // setIsButtonDisabled(true);
                    // setTimeout(() => {
                    //   setIsButtonDisabled(false);
                    // }, 3000)
                  }}
                >
                  Submit
                </Button>
              </form>

              <BetechContactInfo />
            </Row>
          </div>
        )}

        {showSuccessMessage && <BetechContactInfo />}

        <Modal
          showSuccessMessage={showSuccessMessage}
          validation={validation}
        />
      </Container>
    </div>
  )
}

export default ContactUs
