import styled from "styled-components";

export const Description = styled.p`
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Title = styled.h1`
  margin: 100px auto;
  font-family: Neutral Face;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 39px;
  color: #ffffff;
  @media screen and (max-width: 768px) {
    margin: 70px auto 30px;
  }
`;

export const CardsContainer = styled.div`
  padding: 0 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 70px;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
`;

export const Card = styled.div`
  padding: 32px;
  background: #062f38;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 30px;
  @media screen and (max-width: 768px) {
    padding: 20px 15px;
  }
`;

export const Icon = styled.img`
  height: 50px;
  width: auto;
  margin-bottom: 28px;
`;

export const CardTitle = styled.p`
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 33px;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
