// import databasesLogo from "../../../images/technologies_logo/databases_logo.svg";
// import cloudLogo from "../../../images/technologies_logo/cloud_logo.svg";
// import webDevelopmentLogo from "../../../images/technologies_logo/web_development_logo.svg";
// import aiMachineLearningLogo from "../../../images/technologies_logo/ai&machine_learning.svg";
// import programmingLanguagesLogo from "../../../images/technologies_logo/programming_languages.svg";
// import appsWeUseLogo from "../../../images/technologies_logo/apps_we_use.svg";
// import mobileDevelopmentLogo from "../../../images/technologies_logo/mobile_development.svg";
// import jsTsHtmlCss from "../../../images/technologies_logo/js&ts&html&css.svg";

import databasesLogo from "../../../images/technologies_logo/NoBlurLogos/databases1.svg";
import cloudLogo from "../../../images/technologies_logo/NoBlurLogos/cloud1.svg";
import webDevelopmentLogo from "../../../images/technologies_logo/NoBlurLogos/webDev.svg";
import aiMachineLearningLogo from "../../../images/technologies_logo/NoBlurLogos/ai&machine_learning.svg";
import programmingLanguagesLogo from "../../../images/technologies_logo/NoBlurLogos/programming_languages.svg";
import appsWeUseLogo from "../../../images/technologies_logo/NoBlurLogos/apps_we_use.svg";
import mobileDevelopmentLogo from "../../../images/technologies_logo/NoBlurLogos/mobile_development.svg";
import jsTsHtmlCss from "../../../images/technologies_logo/NoBlurLogos/js&ts&html&css.svg";

const data = [
  [databasesLogo, cloudLogo, webDevelopmentLogo, aiMachineLearningLogo],
  [programmingLanguagesLogo, appsWeUseLogo, mobileDevelopmentLogo, jsTsHtmlCss],
];

export default data;
