import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { Container } from "../../Home/Services/ServicesElements.js";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  GetInTouch,
  Title,
  Subtitle,
  Services,
  ServiceTitles,
  Column,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIconLink,
  SocialIcons,
  WebsiteRights,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <Container>
        <FooterWrap>
          {/* Left Section */}
          <GetInTouch>
            <Title>Get in touch!</Title>
            <Subtitle>
              For any query or question, you can contact us at any time at
              info@betech.al
            </Subtitle>
          </GetInTouch>

          {/* Right Section */}
          <Services>
            <ServiceTitles>
              <Column>
                <FooterLink to="/about">About Us</FooterLink>
                {/* <FooterLink to="#">AboutUs</FooterLink> */}
              </Column>
              <Column>
                <FooterLink to="/services">Services</FooterLink>
                {/* <FooterLink to="#">AboutUs</FooterLink> */}
              </Column>
              <Column>
                <FooterLink to="/careers">Careers</FooterLink>
                {/* <FooterLink to="#">AboutUs</FooterLink> */}
              </Column>
            </ServiceTitles>

            <SocialMediaWrap>
              <SocialMedia>
                <SocialIcons>
                  <SocialIconLink
                    href="www.facebook.com"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <FaFacebook />
                  </SocialIconLink>
                  <SocialIconLink
                    href="/"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <FaInstagram />
                  </SocialIconLink>
                  <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                    <FaTwitter />
                  </SocialIconLink>
                  <SocialIconLink
                    href="/"
                    target="_blank"
                    aria-label="LinkedIn"
                  >
                    <FaLinkedin />
                  </SocialIconLink>
                </SocialIcons>
              </SocialMedia>
            </SocialMediaWrap>
          </Services>
        </FooterWrap>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            gap: "20px",
            margin: "30px auto",
          }}
        >
          <SocialLogo to="about" onClick={toggleHome}>
            betech
          </SocialLogo>
          <WebsiteRights>
            © Copyright {new Date().getFullYear()} All rights reserved.
          </WebsiteRights>
        </div>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
