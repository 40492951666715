import React, { useState } from "react";
import Navbar from "../General/Navbar";
import Footer from "../General/Footer";
import Sidebar from "../General/Sidebar";

import {
  InfoContainer1,
  InfoWrapper1,
  TopLine,
  Img1,
  Container,
  Headline,
  Description,
} from "./AboutElements";

import {
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
} from "../General/InfoSection/InfoElements";
import ContactUs from "../Home/ContactUs";

import { About, data } from "./Data";

import "./index.css"

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ backgroundColor: "#000000f3", overflow: "hidden" }}>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <img
        src={require("../../videos/Group 12.svg")}
        style={{
        }}
        className='bannerImage'
        alt="About Us Banner"
      />
      {/* Here goes the content of the page */}
      <Headline className="container" style={{ fontSize: '36px', fontWeight: 'bold', color: '#00B4D8' }}>
        About betech
      </Headline>
      <Headline className="container">{About.headline}</Headline>
      <Description className="container">{About.description1}</Description>

      <div className="container flexRow" style={{}}>
        <Description className="container">{About.description2}</Description>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <img src={require('../../images/About Us/teamWork.jpg')} className='container image' style={{}} alt="teamwork" />
          <div style={{ position: 'absolute', top: 0, backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%' }}></div>
        </div>
      </div>

      <Container>
        <InfoWrapper1>
          <TopLine>We operate Worldwide</TopLine>
        </InfoWrapper1>
        <InfoContainer1>
          <InfoWrapper>
            <InfoRow>
              {/*The first row of the table holding both columns*/}
              <Column1>
                {data.map((item, index) => {
                  if (index >= data.length / 2) {
                    return null;
                  } else {
                    return (
                      <InfoRow key={index}>
                        {/*The row in the first column holding the first state*/}
                        <Img1 require src={item.logo} alt={item.title} />
                        <Column1 style={{ textAlign: "start" }}>
                          {item.title}
                        </Column1>

                        {/* <Column2
                          style={{ display: "flex" }}
                        >
                        </Column2> */}
                      </InfoRow>
                    );
                  }
                })}
              </Column1>

              <Column2>
                {data.map((item, index) => {
                  console.log(index);
                  if (index < data.length / 2) {
                    return null;
                  } else {
                    return (
                      <InfoRow key={index}>
                        {/*The row in the first column holding the first state*/}
                        <Img1 require src={item.logo} />
                        <Column1 style={{ textAlign: "start" }}>
                          {item.title}
                        </Column1>
                      </InfoRow>
                    );
                  }
                })}
              </Column2>
            </InfoRow>
            <div style={{ width: '100%', textAlign: 'center' }}>
              *We are open to collaborate with any country. For any inquieries
              contact us at info@betech.al
            </div>
          </InfoWrapper>
        </InfoContainer1>
      </Container>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default AboutUs;
