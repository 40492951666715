import specialist from '../../../../images/servicesPage/outsourcing/specialist.svg'
import piggyBank from '../../../../images/servicesPage/outsourcing/piggyBank.svg'
import developer from '../../../../images/servicesPage/outsourcing/developer.svg'
import nda from '../../../../images/servicesPage/outsourcing/nda.svg'
import timePeriod from '../../../../images/servicesPage/outsourcing/timePeriod.svg'
import support from '../../../../images/servicesPage/outsourcing/support.svg'


const data = [
    {
        icon: specialist,
        title: 'Professional Specialists',
    },
    {
        icon: piggyBank,
        title: 'Best prices on the market',
    },
    {
        icon: developer,
        title: 'Hire specialists for as long as you need',
    },
    {
        icon: nda,
        title: 'Strict NDA’s as per your needs',
    },
    {
        icon: timePeriod,
        title: 'Free trial period',
    },
    {
        icon: support,
        title: 'Support 24/7',
    },
]


export default data;