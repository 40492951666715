import React from "react";
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import AboutUs from "../components/AboutUs";
// import { AboutObjOne } from "../components/AboutUs/Data";

const AboutUspage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, 
  [pathname]
  );
  return (
    <>
      <AboutUs />
    </>
  );
};

export default AboutUspage;
