import React from 'react'
import { Title, Subtitle, Row, Circle } from './SupportAndMaintenance'

const SupportAndMaintenance = () => {
    return (
        <div>
            <Title>We help reduce your fees and increase your overall revenue</Title>
            <Subtitle font={false}>
                Betech offers full services as a digital agency. In addition to delivering project from the start, we offer support in terms of UI&UX design, web, mobile, ai development, as well as cloud support.
            </Subtitle>
            <Subtitle font={true}>
                We follow the steps shown below to assist you in your project:
            </Subtitle>
            <Row><Circle>1</Circle>Audit of the project</Row>
            <Row><Circle>2</Circle>Find issues and problems</Row>
            <Row><Circle>3</Circle>Detailed plan to address the issues and a maintenance plan of the project</Row>
            <Row><Circle>4</Circle>Apply the plan to eliminate the problems</Row>
            <Row><Circle>5</Circle>Continuous update and maintenance of the project</Row>
        </div>
    )
}

export default SupportAndMaintenance;
