import awsLogo from '../../../../images/servicesPage/Design And Development/Cloud Engineering/aws_logo.svg'
import googleCloudLogo from '../../../../images/servicesPage/Design And Development/Cloud Engineering/googleCloud_logo.svg'
import azureLogo from '../../../../images/servicesPage/Design And Development/Cloud Engineering/azure_logo.svg'
import vmWareLogo from '../../../../images/servicesPage/Design And Development/Cloud Engineering/vmware_logo.svg'
import ciscoLogo from '../../../../images/servicesPage/Design And Development/Cloud Engineering/cisco_logo.svg'
import alibabaCloud from '../../../../images/servicesPage/Design And Development/Cloud Engineering/alibaba_cloud.svg'

// UI&UX Images
import figmaLogo from '../../../../images/servicesPage/Design And Development/UI&UX/figma.svg'
import adobeXDLogo from '../../../../images/servicesPage/Design And Development/UI&UX/adobeXD.svg'
import sketchLogo from '../../../../images/servicesPage/Design And Development/UI&UX/sketch.svg'
import zeplinLogo from '../../../../images/servicesPage/Design And Development/UI&UX/zeplin.svg'

// Artificial Intelligence

import tensorflowLogo from '../../../../images/servicesPage/Design And Development/Artificial Intelligence/tensorflow.svg'
import kerasLogo from '../../../../images/servicesPage/Design And Development/Artificial Intelligence/keras.svg'
import pyTorchLogo from '../../../../images/servicesPage/Design And Development/Artificial Intelligence/pytorch.svg'
import openAILogo from '../../../../images/servicesPage/Design And Development/Artificial Intelligence/openAI.svg'




export const cloudEngineeringData = [
    { title: 'Amazon Web Services', logo: awsLogo },
    { title: 'Google Cloud Platform', logo: googleCloudLogo },
    { title: 'Azure', logo: azureLogo },
    { title: 'Vmware', logo: vmWareLogo },
    { title: 'Cisco', logo: ciscoLogo },
    { title: 'Alibaba Cloud', logo: alibabaCloud },
]


export const UIandUXData = [
    { title: 'Figma', logo: figmaLogo },
    { title: 'Adobe XD', logo: adobeXDLogo },
    { title: 'Sketch', logo: sketchLogo },
    { title: 'Zeplin', logo: zeplinLogo },
]


export const ArtificialIntelligence = [
    { title: 'TensorFlow', logo: tensorflowLogo },
    { title: 'Keras', logo: kerasLogo },
    { title: 'PyTorch', logo: pyTorchLogo },
    { title: 'OpenAI', logo: openAILogo },
]