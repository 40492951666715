import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Dropdown from 'react-dropdown';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/all";


import './services.css'
import Navbar from "../General/Navbar";
import Sidebar from "../General/Sidebar";
import Footer from "../General/Footer";

import {
  ImageContainer,
  GeneralContainer,
  TitleContainer,
  Title,
  Description,
  Tabs,
  TabTitle
} from "./ServicesElements1";

import image from '../../images/charles-deluvio-Lks7vei-eAg-unsplash.jpg'

import Outsourcing from "./Components/Outsourcing";
import Consulting from "./Components/Consulting";
import DesignAndDevelopment from "./Components/DesignAndDevelopment";
import SupportAndMaintenance from "./Components/SupportAndMaintenance/SupportAndMaintenance.jsx";
import ContactUs from "../Home/ContactUs";

const CONSULTING = 'CONSULTING'
const SUPPORT_AND_MAINTENANCE = 'SUPPORT_AND_MAINTENANCE'
const DESIGN_AND_DEVELOPMENT = 'DESIGN_AND_DEVELOPMENT'
const STAFF_AUGMENTATION = 'STAFF_AUGMENTATION'

const Services = ({ location }) => {

  const options = [{ value: CONSULTING, label: 'CONSULTING' }, { value: SUPPORT_AND_MAINTENANCE, label: 'SUPPORT AND MAINTENANCE' }, { value: DESIGN_AND_DEVELOPMENT, label: 'DESIGN AND DEVELOPMENT' }, { value: STAFF_AUGMENTATION, label: 'OUTSOURCING' }]

  const [isOpen, setIsOpen] = React.useState(false);
  const [tab, setTab] = React.useState('CONSULTING')

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { pathname } = useLocation();

  const setTabIfLocation = (prop) => {
    if (prop) {
      setTab(prop.state)
    }
    return;
  }

  useEffect(() => {
    location ? window.scrollTo(0, 400) : window.scrollTo(0, 0);
    location.state && setTabIfLocation(location)
  }, [pathname, location]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#000000f3', color: 'white' }}>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} transparent={false} />
      <ImageContainer image={image}>
        <GeneralContainer className="container">
          <TitleContainer>
            <Title>Our services</Title>
            <Description>We help you grow your business!</Description>
          </TitleContainer>
        </GeneralContainer>
      </ImageContainer>

      <Tabs className="container">
        <TabTitle className={tab === CONSULTING ? 'active-button' : ''} onClick={() => setTab(CONSULTING)}>CONSULTING</TabTitle>
        <TabTitle className={tab === SUPPORT_AND_MAINTENANCE ? 'active-button' : ''} onClick={() => setTab(SUPPORT_AND_MAINTENANCE)}>SUPPORT AND MAINTENANCE</TabTitle>
        <TabTitle className={tab === DESIGN_AND_DEVELOPMENT ? 'active-button' : ''} onClick={() => setTab(DESIGN_AND_DEVELOPMENT)}>DESIGN AND DEVELOPMENT</TabTitle>
        <TabTitle className={tab === STAFF_AUGMENTATION ? 'active-button' : ''} onClick={() => setTab(STAFF_AUGMENTATION)}>OUTSOURCING</TabTitle>
      </Tabs>

      {/* Mobile Only */}
      <div className="box mobile container">
        <Dropdown options={options} onChange={(option) => { setTab(option.value) }} value={tab} placeholder="Select an option" arrowClosed={<IoIosArrowDown />} arrowOpen={<IoIosArrowUp />} />
      </div>


      <div className="container">
        {tab === CONSULTING ? <Consulting /> : tab === SUPPORT_AND_MAINTENANCE ? < SupportAndMaintenance /> : tab === DESIGN_AND_DEVELOPMENT ? < DesignAndDevelopment /> : < Outsourcing />}
      </div>

      <ContactUs />
      <Footer />

    </div >
  );
};

export default Services;
