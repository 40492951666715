import styled from "styled-components";
// import { Colors } from "../../../constants/colors";

export const Separator = styled.div`
  height: 30vh;
  background: linear-gradient(180deg, black, transparent);

  @media screen and (max-width: 768px) {
    height: 300px;
  }
`;

export const Row = styled.div`
  display: flex;
  /* flex-direction: row; */
  /* grid-template-columns: 2fr 3fr; */
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  margin-top: -70px;

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
    margin-top: -30px;
  }
`;

export const Description = styled.div`
  flex: 2 1.5 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 38px;
  /* or 136% */

  letter-spacing: 0.17em;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin: 30px 0;
  }
`;

export const Image = styled.img`
  /* margin-top: -50px ; */
  flex: 2 1 auto;
  height: 50%;
  width: 70%;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin-top: -20px;
  }
`;
