import reliable from '../../../images/outsourcing/reliable.svg'
import satisfaction from '../../../images/outsourcing/satisfaction1.svg'
import professionals from '../../../images/outsourcing/proffesionals.svg'
import trialPeriod from '../../../images/outsourcing/trialPeriod.svg'



const data = [
    {description: '100% Reliable', logo: reliable},
    {description: '100% Satisfaction', logo: satisfaction},
    {description: 'Professionals', logo: professionals},
    {description: 'Free trial Period', logo: trialPeriod},

]

export default data;