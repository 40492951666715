import React from 'react'
// import { Container as GeneralContainer } from '../../Home/Services/ServicesElements'
import { Container as GeneralContainer, Title, Button } from '../../Home/Services/ServicesElements'
import { Container, Row, Description } from './OutsourcingElements.js'
import data from './data'


const Outsourcing = () => {
    return (
        <GeneralContainer className='container'>
            <Title>Outsourcing</Title>
            <Container>
                <Description>
                    Thoroghly checked and picked professionals that will enhance the effectivness and productivity time of your company.
                </Description>
                <Row>
                    {data.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', alignItems: 'flex-end', gap: '10px', margin: '10px 0' }}>
                                <img src={item.logo} alt="" />
                                <div style={{ color: 'white', fontSize: '24px', padding: '10px 0' }}>{item.description}</div>
                            </div>
                        )
                    })}
                </Row>
            </Container>
            <Button to={{ pathname: "/services", state: 'STAFF_AUGMENTATION' }}>Learn More</Button>

        </GeneralContainer>
    )
}

export default Outsourcing