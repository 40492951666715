import styled from "styled-components";

export const InfoContainer1 = styled.div`
  color: #fff;
  /* background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")}; */
  font-language-override: inherit;
  //font-weight: bold;
  font-family: "Verdana";

  @media screen and (max-width: 768px) {
    /* padding: 100px 0; */
  }
`;

export const InfoWrapper1 = styled.div`
  display: flex;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow1 = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWrapper1 = styled.div`
  max-width: 1100px;
  padding-top: 0;
  padding-bottom: 60px;
  text-align: justify;
`;

export const TopLine = styled.p`
  color: pink;
  font-size: 28px;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 50px;
  padding: 20px;

  @media screen and (max-width: 480px) {
      line-height: 32px;
      font-size: 20px;
      width: 60%;
  }
`;

export const Heading1 = styled.h1`
  margin-bottom: 40px;
  text-align: justify;
  align-items: center;
  color: #010101;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "white" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle1 = styled.p`
  max-width: auto;
  text-align: justify;
  margin-bottom: 35px;
  font-size: 15px;
  line-height: auto;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap1 = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: flex-start;
`;

export const ImgWrap1 = styled.div`
  max-width: 50px;
  height: 100%;
`;

export const Img1 = styled.img`
  width: 24px;
  margin: 0 0 10px 0;
  padding-right: 0;
  padding-bottom: 20px ;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;
export const Container = styled.div`
  padding-top: 90px;
  display: block;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: justify;
  width: auto;
  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }
`;
export const Headline = styled.div`
  margin: 50px auto;
  font-size: 28px;
  line-height: 42px;
  color: white;
  @media screen and (max-width: 768px) {
    margin-top: -30px ;
  }
`;
export const Description = styled.div`
  margin: 50px auto;
  font-size: 24px;
  line-height: 36px;
  color: white;
`;
