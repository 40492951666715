import React from 'react'
import { Container, Title, Button } from '../../Home/Services/ServicesElements'
import { AllTiles, TileContainer, TileLogo, TileTitle, TileDescription } from './ITSolutionsElements'
import data from './data'

const ITSolutions = () => {
    return (
        <Container className='container'>
            <Title>In House Solutions</Title>
            <AllTiles>
                {data.map((tile, index) => {
                    return (<TileContainer key={index}>
                        <TileLogo src={tile.logo} alt='' />
                        <TileTitle>{tile.title}</TileTitle>
                        <TileDescription>{tile.description}</TileDescription>
                    </TileContainer>)
                })}
            </AllTiles>

            <Button to={{ pathname: "/services", state: 'DESIGN_AND_DEVELOPMENT' }}>Learn More</Button>

        </Container>
    )
}

export default ITSolutions