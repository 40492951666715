import styled from "styled-components";
import image from "../../images/charles-deluvio-Lks7vei-eAg-unsplash.jpg";
import { Colors } from "../../constants/colors";

export const ImageContainer = styled.div`
  width: 100% !important;
  height: auto;
  /* padding-top: 150px; */
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px) {
    height: 60vh;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
`;

export const GeneralContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  gap: 30px;
  /* padding: 50px; */
  margin: 200px auto;
  @media screen and (max-width: 768px) {
    padding: 100px 0;
    margin: 60px auto;
  }
  //   @media screen and (max-width: 768px) {
  //     height: 1100px;
  //   }

  //   @media screen and (max-width: 480px) {
  //     height: 1300px;
  //   }
`;

export const TitleContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 250px #000000;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 80px 200px 80px 50px;

  @media screen and (max-width: 768px) {
    /* margin-left: 20px; */
    /* margin-top: 70px; */
    padding: 30px 20px 30px 30px;
  }
`;

export const Title = styled.h1`
  font-family: Neutral Face;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 70px;
  width: max-content;
  color: #21cad4;

  @media only screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const Description = styled.p`
  font-family: Neutral Face;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 70px;
  color: #ffffff;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const ServicesIcon = styled.div`
  width: 100%;
  height: 100%;
`;

export const GridContainer = styled.div`
  margin: 50px auto 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CardContainer = styled.div`
  background-color: white;
  /* color: #ffffff; */
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: space-evenly;
  padding: 100px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #211476;
    white-space: nowrap;
  }
`;

/* export const CardIcon = styled.div`
    margin: 10px;
    width: 50px;
`; */

export const CardTitleDescription = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.div`
  margin: 10px;
  font-size: 30px;
`;

export const CardDescription = styled.div`
  margin: 10px;
  font-size: 20px;
`;

export const Tabs = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px auto;
  color: ${Colors.secondary};
  opacity: 0.7;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    display: none;
  }
`;

export const TabTitle = styled.div`
  font-family: Neutral Face;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
