import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'

import BetechHero from "../components/Home/BetechHero";
import Footer from "../components/General/Footer";
import AboutUs from '../components/Home/AboutUs'
import Navbar from "../components/General/Navbar/index";
import Services from "../components/Home/Services/Services";
import Sidebar from "../components/General/Sidebar";
import ContactUs from '../components/Home/ContactUs'
import Technologies from "../components/Home/Technologies";
import ITSolutions from "../components/Home/ITSolutions";
import Outsourcing from "../components/Home/Outsourcing";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  },
    [pathname]
  );

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.90)', overflow: "hidden" }}>
      {isOpen &&
        <Sidebar isOpen={isOpen} toggle={toggle} />}
      {/* {!isOpen && */}
      <>
        <Navbar toggle={toggle} transparent={false} />
        <BetechHero />
        <AboutUs />
        <Technologies />
        <Services />
        <Outsourcing />
        <ITSolutions />
        <ContactUs />
        <Footer />
      </>
      {/* } */}
    </div>
  );
};

export default Home;
