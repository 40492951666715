import styled from "styled-components";
import { Colors } from "../../../constants/colors";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin: 20px 0 50px 0;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 20px;
    margin: 25px auto;
  }
`;

export const Choice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  padding: 10px 30px;
  color: white;
  width: auto;
  text-align: center;

  &:hover {
    cursor: pointer;
    background-color: white;
    color: black;
    transition: 0.17s ease-in;
  }
  &:target {
    cursor: pointer;
    background-color: white;
    color: black;
    transition: 0.17s ease-in;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 70px;
  justify-content: space-between;
  align-items: center;
  height: max-content;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
`;

export const Input = styled.input`
  /* border: none;
  border-bottom: rgba(255, 255, 255, 0.7) 2px solid;
  width: 100%;
  color: white;
  background-color: transparent;
  outline: none;
  margin-bottom: 30px; */
`;

export const TextArea = styled.textarea`
  border: none;
  border-bottom: rgba(255, 255, 255, 0.7) 2px solid;
  width: 100%;
  color: white;
  background-color: transparent;
  outline: none;
  margin-bottom: 30px;
`;

export const Label = styled.label`
  color: rgba(255, 255, 255, 0.7);
`;

export const HrefLabel = styled.span`
  padding: 0 5px;
  color: rgba(255, 255, 255, 0.7);
`;

export const InfoHref = styled.a`
  color: rgba(255, 255, 255, 0.7);
  /* padding-bottom: 20px; */
  text-decoration: none;
  margin-left: 10px;
`;


export const Button = styled.button`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 25px auto;
  width: fit-content;
  padding: 18px 80px;
  color: ${({ styleGray }) => (styleGray ? '#333333' : '#ffffff')};
  background-color: ${({ styleGray }) => (styleGray ? '#777777' : Colors.secondary)};
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  /* background-color: ${Colors.secondary}; */

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    background-color: ${Colors.primary};
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 50px;
  }
`;
