import styled from "styled-components";

export const Title = styled.div`
  margin: 50px auto 20px;
  font-family: Neutral Face;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: #ffffff;
`;

export const Subtitle = styled.div`
  margin: 20px auto;
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: ${({ font }) => (font ? "bold" : "normal")};
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
`;

export const Circle = styled.div`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 2px;

    background: #fff;
    border: 2px solid #000;
    color: #000;
    text-align: center;
    font: 16px;
`;

export const Row = styled.div`
    font-size: 20px ;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
`