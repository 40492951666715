import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const FooterContainer = styled.div`
  /* background-color: #101522; */
  /* background:linear-gradient(180deg, #000000 0%, #27272E 100%); */
  /* background-color: #27272e; */
  background-color: rgb(33 55 55);
  /* background-color: rgb(0 0 0 / 75%); */
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  gap: 20px;

  @media screen and (max-width: 738px) {
    flex-direction: column;
    padding: 0px;
  }
`;

export const GetInTouch = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #ffffff;
  @media screen and (max-width: 738px) {
    width: 100%;
    border: none;
  }
`;

export const Title = styled.h1`
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 125px;
  color: #d1d1d1;
  @media screen and (max-width: 738px) {
    margin: 0 auto;
  }
`;

export const Subtitle = styled.h2`
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  color: #969696;
  @media screen and (max-width: 738px) {
    margin: 0 20px;
  }
`;

export const Services = styled.div`
  margin-left: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 738px) {
    margin: 0 20px;
    width: 100%;
  }
`;

export const ServiceTitles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 768px) {
    padding-left: 40px ;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

// export const FooterLinksContainer = styled.div`
//   display: flex;
//   justify-content: center;

//   @media screen and (max-max-width: 820px) {
//     padding-top: 32px;
//   }
// `;

// export const FooterLinkWrapper = styled.div`
//   display: flex;

//   @media screen and (max-width: 820px) {
//     flex-direction: column;
//   }
// `;

// export const FooterLinkItems = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin: 16px;
//   text-align: left;
//   width: 160px;
//   box-sizing: border-box;
//   color: #fff;

//   @media screen and (max-width: 420px) {
//     margin: 0;
//     padding: 10px;
//     width: 100%;
//   }
// `;

// export const FooterLinkTitle = styled.h1`
//   font-size: 18px;
//   margin-bottom: 16px;
//   font-weight: bold;
// `;

export const FooterLink = styled(Link)`
  color: #d3d4d4;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: navy;
    transition: 0.3s ease-in-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 30px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  } ;
`;

export const SocialLogo = styled(LinkS)`
  color: #ffffffd9;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  /* margin-bottom: 16px; */
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  color: #ffffffd9;
  /* margin-bottom: 16px; */
  /* padding: 32px; */
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;
