import React from "react";
import UK from "../../images/countries/UK.svg";
import GERMANY from "../../images/countries/GERMANY.svg";
import NORWAY from "../../images/countries/NORWAY.svg";
import DENMARK from "../../images/countries/DENMARK.svg";
import FINLAND from "../../images/countries/FINLAND.svg";
import SWEDEN from "../../images/countries/SWEDEN.svg";
import US from "../../images/countries/US.svg";
import AUSTRIA from "../../images/countries/AUSTRIA.svg";
import ITALY from "../../images/countries/ITALY.svg";
import SPAIN from "../../images/countries/SPAIN.svg";
import NETHERLANDS from "../../images/countries/NETHERLANDS.svg";
import BELGIUM from "../../images/countries/BELGIUM.svg";
import FRANCE from "../../images/countries/FRANCE.svg";
import SWITZERLAND from "../../images/countries/SWITZERLAND.svg";

export const About = {
  // id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About Us",
  pageLink: "AboutUs",
  buttonLabel: "Learn More",
  headline:
    "betech is a Software Development, Recruitment & Outsourcing vendor enabling clients to navigate their Digital Transformation and assist in delivering solutions to organizational bottlenecks as well as reduced costs of the project.",
  description1: (
    <div>
      <p style={{ padding: 5 }}>
        betech delivers Software Technology Recruitment, Professional Consulting for software development companies as well as Outsourcing and Nearshore services. betech offers IT solutions in many technologies through our team of professionals. All this in order for your team to deliver better, faster and cost-friendly projects.
      </p>
      <p style={{ padding: 5 }}>
        We at betech understand the struggle of navigating your way through digital transformation so we propound IT Solutions, Professional Consulting and Support & Maintenance for any type of project.
      </p>
      <p style={{ padding: 5 }}>
        Our team conducts a thorough research and picks the perfect team to help software companies deliver their projects according to their specified bulletins.
      </p>
    </div>
  ),
  description2: (
    <div>
      <ul>
        <li style={{ padding: 5 }}>
          <b>Our Mission</b> : Empowering our customers to deliver better,
          faster and cost-friendly projects.
        </li>
        <li style={{ padding: 5 }}>
          <b>Our Vision</b> : To give the best solution to our customers and to
          union the IT community as much as possible.
        </li>
        <li style={{ padding: 5 }}>
          <b>Our Values</b> : Open mindset, teamwork, time management,
          commercial awareness.
        </li>
      </ul>
    </div>
  ),
  imgStart: true,
  img: require("../../images/svg-3.svg"),
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const data = [
  { logo: UK, title: "United Kingdom" },
  { logo: GERMANY, title: "Germany" },
  { logo: NORWAY, title: "Norway" },
  { logo: DENMARK, title: "Denmark" },
  { logo: FINLAND, title: "Finland" },
  { logo: SWEDEN, title: "Sweden" },
  { logo: US, title: "United States" },
  { logo: AUSTRIA, title: "Austria" },
  { logo: ITALY, title: "Italy" },
  { logo: SPAIN, title: "Spain" },
  { logo: NETHERLANDS, title: "Netherlands" },
  { logo: BELGIUM, title: "Belgium" },
  { logo: FRANCE, title: "France" },
  { logo: SWITZERLAND, title: "Switzerland" },
];
