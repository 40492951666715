import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 25px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 99px;
  letter-spacing: 0.075em;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Chunk = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
`;

export const Percent = styled.div`
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 84px;

  color: #ffffff;
`;

export const Description = styled.div`
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 42px;
  color: #ffffff;
  margin-right: 30px;
`;

export const Bullet = styled.div`
  scale: 1.7;
  &::before {
    content: "•";
    color: white;
  }
`;
